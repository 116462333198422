.mdc-typography {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.mdc-typography--headline1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 300;
  letter-spacing: -0.01562em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.00833em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline3 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline4 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.00735em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline5 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline6 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--caption {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-typography--overline {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.16667em;
  text-decoration: none;
  text-transform: uppercase; }

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 4px; }
  .mdc-button::before, .mdc-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-button::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-button.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button::before, .mdc-button::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .mdc-button:active {
    outline: none; }
  .mdc-button:hover {
    cursor: pointer; }
  .mdc-button:disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.37);
    cursor: default;
    pointer-events: none; }
  .mdc-button.mdc-button--dense {
    border-radius: 4px; }
  .mdc-button:not(:disabled) {
    background-color: transparent; }
  .mdc-button:not(:disabled) {
    color: #FEDBD0;
    /* @alternate */
    color: var(--mdc-theme-primary, #FEDBD0); }
  .mdc-button::before, .mdc-button::after {
    background-color: #FEDBD0; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button::before, .mdc-button::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #FEDBD0); } }
  .mdc-button:hover::before {
    opacity: 0.08; }
  .mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }
  .mdc-button .mdc-button__icon {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top; }
    [dir="rtl"] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir="rtl"] {
      /* @noflip */
      margin-left: 8px;
      /* @noflip */
      margin-right: 0; }
  .mdc-button svg.mdc-button__icon {
    fill: currentColor; }

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px; }
  [dir="rtl"] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--unelevated .mdc-button__icon,
  .mdc-button--unelevated .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--outlined .mdc-button__icon,
  .mdc-button--outlined .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: -4px; }

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px; }
  .mdc-button--raised:disabled,
  .mdc-button--unelevated:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.37); }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    background-color: #FEDBD0; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised:not(:disabled),
      .mdc-button--unelevated:not(:disabled) {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #FEDBD0); } }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    color: #000;
    /* @alternate */
    color: var(--mdc-theme-on-primary, #000); }
  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    background-color: #000; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised::before, .mdc-button--raised::after,
      .mdc-button--unelevated::before,
      .mdc-button--unelevated::after {
        /* @alternate */
        background-color: var(--mdc-theme-on-primary, #000); } }
  .mdc-button--raised:hover::before,
  .mdc-button--unelevated:hover::before {
    opacity: 0.04; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
  .mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button--raised:not(.mdc-ripple-upgraded)::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button--raised.mdc-ripple-upgraded,
  .mdc-button--unelevated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-button--raised:hover, .mdc-button--raised:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:disabled {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-button--outlined {
  border-style: solid;
  padding: 0 14px 0 14px;
  border-width: 2px; }
  .mdc-button--outlined:disabled {
    border-color: rgba(0, 0, 0, 0.37); }
  .mdc-button--outlined:not(:disabled) {
    border-color: #FEDBD0;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #FEDBD0); }

.mdc-button--dense {
  height: 32px;
  font-size: .8125rem; }

.button {
  border-radius: 24px;
  font-size: 16px; }
  .button.mdc-button--dense {
    border-radius: 24px; }
  .button:not(:disabled) {
    border-color: #ffffff; }
  .button .mdc-button__label {
    color: #ffffff;
    text-transform: none; }

.button.secondary-button:not(:disabled) {
  background-color: #FCD120; }

.button.secondary-button:not(:disabled) {
  border-color: #212121; }

.button.secondary-button .mdc-button__label {
  color: #212121; }

@media screen and (min-width: 425px) {
  .button {
    border-radius: 38px;
    padding: 0 18px;
    font-size: 20px;
    height: 44px; }
    .button.mdc-button--dense {
      border-radius: 38px; } }

.mdc-card {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

.mdc-card--outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #e0e0e0; }

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .mdc-card__media::before {
    display: block;
    content: ""; }

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }

.mdc-card__media--square::before {
  margin-top: 100%; }

.mdc-card__media--16-9::before {
  margin-top: 56.25%; }

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box; }

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden; }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-card__primary-action::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-card__primary-action.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-card__primary-action.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-card__primary-action.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    background-color: #000; }
  .mdc-card__primary-action:hover::before {
    opacity: 0.04; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-card__primary-action.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px; }

.mdc-card__actions--full-bleed {
  padding: 0; }

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box; }

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
  flex-grow: 1;
  justify-content: flex-end; }

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0; }
  [dir="rtl"] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 16px; }

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none; }
  .mdc-card__action:focus {
    outline: none; }

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px; }
  [dir="rtl"] .mdc-card__action--button, .mdc-card__action--button[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: 0; }
  .mdc-card__action--button:last-child {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */
  text-align: left; }
  [dir="rtl"] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir="rtl"] {
    /* @noflip */
    text-align: right; }

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px; }

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

.speakers-section {
  background: #f9f9f9;
  color: #212121;
  padding: 32px; }
  .speakers-section .more-speakers {
    text-align: center; }
  .speakers-section .speakers-container {
    display: grid;
    grid-gap: 16px;
    margin-top: 32px;
    justify-content: center;
    grid-column-gap: 16px;
    grid-template-columns: 256px; }
    .speakers-section .speakers-container .speaker {
      display: grid;
      justify-items: center;
      border-radius: 24px 8px;
      padding: 16px;
      width: 256px;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      height: 420px; }
      .speakers-section .speakers-container .speaker-photo {
        border-radius: 50%;
        width: 100%; }
      .speakers-section .speakers-container .speaker-name {
        text-align: center;
        margin: 4px 0 0 0;
        font-size: 1.5rem;
        line-height: 1.5rem; }
      .speakers-section .speakers-container .speaker-position {
        margin: 0 0 12px 0;
        font-size: 1rem;
        line-height: 1.25rem;
        text-align: center; }
      .speakers-section .speakers-container .speaker-company {
        height: 36px; }

@media screen and (min-width: 600px) {
  .speakers-section .speakers-container {
    grid-template-columns: repeat(2, 256px); } }

@media screen and (min-width: 832px) {
  .speakers-section .speakers-container {
    grid-template-columns: repeat(3, 256px); } }

@media screen and (min-width: 1136px) {
  .speakers-section .speakers-container {
    grid-template-columns: repeat(4, 256px); } }

@media screen and (min-width: 1440px) {
  .speakers-section .speakers-container {
    grid-template-columns: repeat(5, 256px); } }

.mdc-card {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

.mdc-card--outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #e0e0e0; }

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .mdc-card__media::before {
    display: block;
    content: ""; }

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }

.mdc-card__media--square::before {
  margin-top: 100%; }

.mdc-card__media--16-9::before {
  margin-top: 56.25%; }

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box; }

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden; }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-card__primary-action::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-card__primary-action.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-card__primary-action.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-card__primary-action.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    background-color: #000; }
  .mdc-card__primary-action:hover::before {
    opacity: 0.04; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-card__primary-action.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px; }

.mdc-card__actions--full-bleed {
  padding: 0; }

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box; }

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
  flex-grow: 1;
  justify-content: flex-end; }

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0; }
  [dir="rtl"] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 16px; }

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none; }
  .mdc-card__action:focus {
    outline: none; }

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px; }
  [dir="rtl"] .mdc-card__action--button, .mdc-card__action--button[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: 0; }
  .mdc-card__action--button:last-child {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */
  text-align: left; }
  [dir="rtl"] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir="rtl"] {
    /* @noflip */
    text-align: right; }

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px; }

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

.topics-container {
  display: flex;
  flex-direction: row;
  height: 16px;
  margin: 4px 0; }
  .topics-container .topic {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 10px; }
    .topics-container .topic-dot {
      border-radius: 6px;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      margin-bottom: 1px; }
    .topics-container .topic-name {
      margin: 0; }
  .topics-container .topic:first-child {
    margin-left: 0; }

.topic-dot.flutter {
  background: #00BCD4; }

.topic-dot.dart {
  background: #03A9F4; }

.schedule-section {
  display: grid;
  justify-content: center;
  color: #212121;
  justify-items: center;
  padding: 16px 0; }
  .schedule-section .schedule-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 32px;
    padding: 0 16px;
    width: calc(100% - 32px); }
    .schedule-section .schedule-container .talks-container {
      display: grid;
      grid-gap: 16px; }
      .schedule-section .schedule-container .talks-container .schedule-item, .schedule-section .schedule-container .talks-container .talk {
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        border-radius: 24px 8px;
        padding: 16px; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-1, .schedule-section .schedule-container .talks-container .talk.row-1 {
          grid-row: 1; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-2, .schedule-section .schedule-container .talks-container .talk.row-2 {
          grid-row: 2; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-3, .schedule-section .schedule-container .talks-container .talk.row-3 {
          grid-row: 3; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-4, .schedule-section .schedule-container .talks-container .talk.row-4 {
          grid-row: 4; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-5, .schedule-section .schedule-container .talks-container .talk.row-5 {
          grid-row: 5; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-6, .schedule-section .schedule-container .talks-container .talk.row-6 {
          grid-row: 6; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-7, .schedule-section .schedule-container .talks-container .talk.row-7 {
          grid-row: 7; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-8, .schedule-section .schedule-container .talks-container .talk.row-8 {
          grid-row: 8; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-9, .schedule-section .schedule-container .talks-container .talk.row-9 {
          grid-row: 9; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-10, .schedule-section .schedule-container .talks-container .talk.row-10 {
          grid-row: 10; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-11, .schedule-section .schedule-container .talks-container .talk.row-11 {
          grid-row: 11; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-12, .schedule-section .schedule-container .talks-container .talk.row-12 {
          grid-row: 12; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-13, .schedule-section .schedule-container .talks-container .talk.row-13 {
          grid-row: 13; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-14, .schedule-section .schedule-container .talks-container .talk.row-14 {
          grid-row: 14; }
        .schedule-section .schedule-container .talks-container .schedule-item.row-15, .schedule-section .schedule-container .talks-container .talk.row-15 {
          grid-row: 15; }
      .schedule-section .schedule-container .talks-container .schedule-item {
        width: 100%; }
        .schedule-section .schedule-container .talks-container .schedule-item .item-name {
          font-size: 1.25rem;
          line-height: 1.25rem;
          text-align: center;
          font-weight: 400;
          margin: 0; }
        .schedule-section .schedule-container .talks-container .schedule-item.track {
          border: none;
          box-shadow: none; }
        .schedule-section .schedule-container .talks-container .schedule-item.disable-hover::before {
          background-color: white; }
      .schedule-section .schedule-container .talks-container .talk-name {
        font-size: 1.25rem;
        line-height: 1.25rem;
        margin: 0 0 4px 0;
        font-weight: 400; }
      .schedule-section .schedule-container .talks-container .talk .mobile-description .mobile-description-text {
        margin: 8px 0 4px;
        font-weight: bold; }
      .schedule-section .schedule-container .talks-container .talk .description .description-text {
        margin: 0 0 8px; }
      .schedule-section .schedule-container .talks-container .talk .speakers-container {
        display: grid;
        flex: 1;
        align-items: flex-end;
        grid-gap: 8px;
        grid-template-columns: 1fr; }
        .schedule-section .schedule-container .talks-container .talk .speakers-container .speaker-container {
          display: flex;
          align-items: center;
          flex-direction: row; }
          .schedule-section .schedule-container .talks-container .talk .speakers-container .speaker-container .speaker-photo {
            height: 48px;
            border-radius: 50%; }
          .schedule-section .schedule-container .talks-container .talk .speakers-container .speaker-container .speaker-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 8px;
            margin-bottom: 7px; }
            .schedule-section .schedule-container .talks-container .talk .speakers-container .speaker-container .speaker-text .name {
              font-size: 1.1rem;
              line-height: 1.1rem;
              margin: 0 0 4px 0; }
            .schedule-section .schedule-container .talks-container .talk .speakers-container .speaker-container .speaker-text .position {
              margin: 0;
              font-size: 0.85rem;
              line-height: 0.85rem; }
    .schedule-section .schedule-container .times-container {
      display: none; }

@media screen and (max-width: 767px) {
  .no-mobile {
    display: none; }
  .schedule-section .schedule-container .talks-container .full-column {
    height: 57px; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-1 {
      grid-row: 1/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-2 {
      grid-row: 2/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-3 {
      grid-row: 3/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-4 {
      grid-row: 4/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-5 {
      grid-row: 5/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-6 {
      grid-row: 6/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-7 {
      grid-row: 7/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-8 {
      grid-row: 8/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-9 {
      grid-row: 9/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-10 {
      grid-row: 10/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-11 {
      grid-row: 11/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-12 {
      grid-row: 12/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-13 {
      grid-row: 13/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-14 {
      grid-row: 14/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-15 {
      grid-row: 15/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-16 {
      grid-row: 16/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-17 {
      grid-row: 17/span 2; }
    .schedule-section .schedule-container .talks-container .full-column.mobile-row-18 {
      grid-row: 18/span 2; }
  .schedule-section .schedule-container .talks-container .mobile-row-1 {
    grid-row: 1; }
  .schedule-section .schedule-container .talks-container .mobile-row-2 {
    grid-row: 2; }
  .schedule-section .schedule-container .talks-container .mobile-row-3 {
    grid-row: 3; }
  .schedule-section .schedule-container .talks-container .mobile-row-4 {
    grid-row: 4; }
  .schedule-section .schedule-container .talks-container .mobile-row-5 {
    grid-row: 5; }
  .schedule-section .schedule-container .talks-container .mobile-row-6 {
    grid-row: 6; }
  .schedule-section .schedule-container .talks-container .mobile-row-7 {
    grid-row: 7; }
  .schedule-section .schedule-container .talks-container .mobile-row-8 {
    grid-row: 8; }
  .schedule-section .schedule-container .talks-container .mobile-row-9 {
    grid-row: 9; }
  .schedule-section .schedule-container .talks-container .mobile-row-10 {
    grid-row: 10; }
  .schedule-section .schedule-container .talks-container .mobile-row-11 {
    grid-row: 11; }
  .schedule-section .schedule-container .talks-container .mobile-row-12 {
    grid-row: 12; }
  .schedule-section .schedule-container .talks-container .mobile-row-13 {
    grid-row: 13; }
  .schedule-section .schedule-container .talks-container .mobile-row-14 {
    grid-row: 14; }
  .schedule-section .schedule-container .talks-container .mobile-row-15 {
    grid-row: 15; }
  .schedule-section .schedule-container .talks-container .mobile-row-16 {
    grid-row: 16; }
  .schedule-section .schedule-container .talks-container .mobile-row-17 {
    grid-row: 17; }
  .schedule-section .schedule-container .talks-container .mobile-row-18 {
    grid-row: 18; } }

@media screen and (min-width: 768px) {
  .schedule-section .schedule-container {
    grid-template-columns: 80px 1fr;
    max-width: 656px;
    width: 100%;
    position: relative;
    left: -40px; }
    .schedule-section .schedule-container .talks-container {
      grid-template-rows: 57px 57px 1fr 1fr 1fr 1fr 57px 1fr 1fr 1fr 57px 1fr 1fr 1fr 57px; }
      .schedule-section .schedule-container .talks-container .mobile-description {
        display: none; }
    .schedule-section .schedule-container .times-container {
      display: grid;
      grid-template-rows: 57px 57px 1fr 1fr 1fr 1fr 57px 1fr 1fr 1fr 57px 1fr 1fr 1fr 57px;
      grid-row-gap: 16px; }
      .schedule-section .schedule-container .times-container .time {
        margin: 16px 0;
        font-size: 1.25rem;
        line-height: 1.25rem; } }

@media screen and (min-width: 1024px) {
  .schedule-section .schedule-container {
    max-width: 912px; } }

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

.mdc-dialog {
  display: none;
  z-index: 7; }
  .mdc-dialog .mdc-dialog__surface {
    background-color: #fff;
    /* @alternate */
    background-color: var(--mdc-theme-surface, #fff); }
  .mdc-dialog .mdc-dialog__scrim {
    background-color: rgba(0, 0, 0, 0.32); }
  .mdc-dialog .mdc-dialog__title {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-dialog .mdc-dialog__content {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title,
  .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions {
    border-color: rgba(0, 0, 0, 0.12); }
  .mdc-dialog .mdc-dialog__surface {
    min-width: 280px; }
  @media (max-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: calc(100vw - 32px); } }
  @media (min-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: 560px; } }
  .mdc-dialog .mdc-dialog__surface {
    max-height: calc(100vh - 32px); }
  .mdc-dialog .mdc-dialog__surface {
    border-radius: 4px; }

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1; }

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0; }

.mdc-dialog__surface {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__surface,
  [dir="rtl"] .mdc-dialog .mdc-dialog__surface {
    text-align: right; }

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent; }
  .mdc-dialog__title::before {
    display: inline-block;
    width: 0;
    height: 40px;
    content: "";
    vertical-align: 0; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__title,
  [dir="rtl"] .mdc-dialog .mdc-dialog__title {
    text-align: right; }

.mdc-dialog--scrollable .mdc-dialog__title {
  padding-bottom: 15px; }

.mdc-dialog__content {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .mdc-dialog__content > :first-child {
    margin-top: 0; }
  .mdc-dialog__content > :last-child {
    margin-bottom: 0; }

.mdc-dialog__title + .mdc-dialog__content {
  padding-top: 0; }

.mdc-dialog--scrollable .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px; }

.mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 6px 0 0; }

.mdc-dialog--scrollable .mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 0; }

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent; }
  .mdc-dialog--stacked .mdc-dialog__actions {
    flex-direction: column;
    align-items: flex-end; }

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  text-align: right; }
  [dir="rtl"] .mdc-dialog__button, .mdc-dialog__button[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px; }
  .mdc-dialog__button:first-child {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__button,
  [dir="rtl"] .mdc-dialog .mdc-dialog__button {
    text-align: left; }
  .mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
    margin-top: 12px; }

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex; }

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear; }

.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear; }

.mdc-dialog--closing .mdc-dialog__container {
  transform: scale(1); }

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1; }

.mdc-dialog--open .mdc-dialog__container {
  transform: scale(1);
  opacity: 1; }

.mdc-dialog-scroll-lock {
  overflow: hidden; }

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-icon-button::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-icon-button.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button::before, .mdc-icon-button::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button svg,
  .mdc-icon-button img {
    width: 24px;
    height: 24px; }
  .mdc-icon-button:disabled {
    color: rgba(0, 0, 0, 0.38);
    /* @alternate */
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    background-color: #000; }
  .mdc-icon-button:hover::before {
    opacity: 0.04; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-icon-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-icon-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-icon-button__icon {
  display: inline-block; }
  .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: none; }

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none; }
  .mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: inline-block; }

.topics-container {
  display: flex;
  flex-direction: row;
  height: 16px;
  margin: 4px 0; }
  .topics-container .topic {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 10px; }
    .topics-container .topic-dot {
      border-radius: 6px;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      margin-bottom: 1px; }
    .topics-container .topic-name {
      margin: 0; }
  .topics-container .topic:first-child {
    margin-left: 0; }

.topic-dot.flutter {
  background: #00BCD4; }

.topic-dot.dart {
  background: #03A9F4; }

.mdc-dialog .mdc-dialog__container {
  display: grid;
  width: 100%; }
  .mdc-dialog .mdc-dialog__container .mdc-dialog__surface {
    display: grid;
    grid-template-columns: 1fr;
    min-width: calc(100vw - 16px);
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: auto;
    padding: 0 16px;
    border-radius: 0; }
    .mdc-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-header {
      display: flex;
      justify-content: flex-end; }
    .mdc-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      margin-bottom: 16px; }

.mdc-dialog.info-dialog .mdc-dialog__container .mdc-dialog__surface {
  min-width: calc(100vw - 32px);
  max-width: calc(100vw - 32px);
  padding: 0 12px; }
  .mdc-dialog.info-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-header {
    position: relative;
    left: 12px; }
  .mdc-dialog.info-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body {
    text-align: left;
    margin: 0;
    justify-items: left;
    margin-bottom: 16px; }
    .mdc-dialog.info-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body .schedule-item-title {
      margin: 0; }

.speaker-dialog .mdc-dialog__container {
  min-height: 100vh; }
  .speaker-dialog .mdc-dialog__container .dialog-body .speaker-info {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center; }
    .speaker-dialog .mdc-dialog__container .dialog-body .speaker-info .speaker-photo {
      height: 200px;
      width: 200px;
      border-radius: 50%; }
    .speaker-dialog .mdc-dialog__container .dialog-body .speaker-info .speaker-name {
      margin: 16px 0 0 0;
      font-size: 1.75rem;
      line-height: 1.75rem; }
    .speaker-dialog .mdc-dialog__container .dialog-body .speaker-info .speaker-position {
      margin: 12px 0 16px 0;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center; }
    .speaker-dialog .mdc-dialog__container .dialog-body .speaker-info .speaker-company {
      height: 36px; }
    .speaker-dialog .mdc-dialog__container .dialog-body .speaker-info .speaker-about {
      display: flex;
      width: 100%;
      max-width: 100%; }
      .speaker-dialog .mdc-dialog__container .dialog-body .speaker-info .speaker-about .text {
        text-align: left;
        justify-content: center;
        overflow-wrap: break-word; }
  .speaker-dialog .mdc-dialog__container .dialog-body .talk-container {
    width: 100%; }
    .speaker-dialog .mdc-dialog__container .dialog-body .talk-container .talk-title {
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.5rem; }
    .speaker-dialog .mdc-dialog__container .dialog-body .talk-container .talk-level {
      margin: 12px 0;
      font-size: 1rem;
      line-height: 1rem; }
    .speaker-dialog .mdc-dialog__container .dialog-body .talk-container .topics-container {
      display: flex;
      flex-direction: row;
      height: 16px;
      margin: 4px 0; }
      .speaker-dialog .mdc-dialog__container .dialog-body .talk-container .topics-container .topic {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 10px; }
        .speaker-dialog .mdc-dialog__container .dialog-body .talk-container .topics-container .topic-dot {
          border-radius: 6px;
          width: 10px;
          height: 10px;
          margin-right: 4px;
          margin-bottom: 1px; }
        .speaker-dialog .mdc-dialog__container .dialog-body .talk-container .topics-container .topic-name {
          margin: 0; }
      .speaker-dialog .mdc-dialog__container .dialog-body .talk-container .topics-container .topic:first-child {
        margin-left: 0; }
  .speaker-dialog .mdc-dialog__container .dialog-body .co-speaker-text {
    margin: 0 0 12px 0;
    font-size: 1.5rem;
    line-height: 1.5rem; }
  .speaker-dialog .mdc-dialog__container .dialog-body .talk-speakers-container {
    display: flex;
    flex-direction: column; }
    .speaker-dialog .mdc-dialog__container .dialog-body .talk-speakers-container .speaker-container {
      display: flex;
      align-items: center;
      flex-direction: row; }
      .speaker-dialog .mdc-dialog__container .dialog-body .talk-speakers-container .speaker-container:first-child {
        margin-bottom: 16px; }
      .speaker-dialog .mdc-dialog__container .dialog-body .talk-speakers-container .speaker-container .speaker-photo {
        height: 48px;
        border-radius: 50%; }
      .speaker-dialog .mdc-dialog__container .dialog-body .talk-speakers-container .speaker-container .speaker-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;
        margin-bottom: 7px; }
        .speaker-dialog .mdc-dialog__container .dialog-body .talk-speakers-container .speaker-container .speaker-text .name {
          font-size: 1.1rem;
          line-height: 1.1rem;
          margin: 0 0 4px 0; }
        .speaker-dialog .mdc-dialog__container .dialog-body .talk-speakers-container .speaker-container .speaker-text .position {
          margin: 0;
          font-size: 0.85rem;
          line-height: 0.85rem; }

@media screen and (min-width: 400px) {
  .mdc-dialog .mdc-dialog__container .mdc-dialog__surface {
    min-width: 400px;
    max-width: 400px;
    height: unset;
    padding: 4px;
    border-radius: 4px; }
    .mdc-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body {
      padding: 0 16px; }
  .mdc-dialog.info-dialog .mdc-dialog__container .mdc-dialog__surface {
    min-width: 360px;
    max-width: 360px; }
  .speaker-dialog .mdc-dialog__container {
    min-height: 200px; }
    .speaker-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body .speaker-about .text {
      text-align: justify; } }

@media screen and (min-width: 1024px) {
  .mdc-dialog .mdc-dialog__container .mdc-dialog__surface {
    min-width: 500px;
    max-width: 500px;
    height: unset;
    padding: 4px;
    border-radius: 4px; }
    .mdc-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body {
      padding: 0 16px; }
  .speaker-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body .talk-speakers-container {
    flex-direction: row; }
    .speaker-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body .talk-speakers-container .speaker-container:first-child {
      margin-right: 16px;
      margin-bottom: 0; }
  .speaker-dialog .mdc-dialog__container .mdc-dialog__surface .dialog-body .speaker-about .text {
    text-align: justify; } }

.info-section {
  padding: 32px; }
  .info-section .info-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 32px;
    grid-gap: 16px; }
    .info-section .info-container .text-container {
      color: black;
      width: 100%; }
      .info-section .info-container .text-container .title {
        font-weight: bold; }
    .info-section .info-container .map-container iframe {
      width: 100%; }

@media screen and (min-width: 768px) {
  .info-section {
    display: grid;
    justify-content: center;
    padding: 32px; }
    .info-section .info-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 656px;
      width: 100%; }
      .info-section .info-container .text-container {
        color: black; }
        .info-section .info-container .text-container .title {
          font-weight: bold;
          color: black; }
    .info-section .map-container {
      display: flex;
      min-height: 300px; }
      .info-section .map-container iframe {
        flex: 1; } }

@media screen and (min-width: 1024px) {
  .info-section .info-container {
    max-width: 830px; } }

body {
  background: #f9f9f9;
  color: white;
  margin: 0; }

.header {
  background-image: url(../images/kuneticka-hora.jpg);
  background-position: center center;
  background-repeat: no-repeat; }
  .header-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    min-height: calc(100vh - 48px);
    background-color: rgba(0, 0, 0, 0.4); }
    .header-container .header-title {
      display: grid;
      grid-template-columns: 120px 1fr;
      grid-column-gap: 8px;
      width: 100%; }
      .header-container .header-title .header-logo-container .main-logo {
        width: 100%; }
      .header-container .header-title .header-title-headings .place, .header-container .header-title .header-title-headings .date {
        text-align: right;
        line-height: unset; }
      .header-container .header-title .header-title-headings .date {
        font-size: 1rem;
        margin: 0; }
      .header-container .header-title .header-title-headings .place {
        font-size: 0.75rem;
        line-height: unset;
        margin: 4px 0 0 0; }
    .header-container .header-content {
      display: grid;
      flex: 1;
      justify-content: center;
      align-content: flex-end; }
      .header-container .header-content .register-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .header-container .header-content .register-container .buttons-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center; }
          .header-container .header-content .register-container .buttons-container .register-button, .header-container .header-content .register-container .buttons-container .cfp-button {
            margin: 8px; }
        .header-container .header-content .register-container .info-text {
          margin: 0;
          font-size: 14px;
          line-height: unset;
          text-align: center; }
          .header-container .header-content .register-container .info-text p {
            margin: 6px 0; }
            .header-container .header-content .register-container .info-text p.question {
              font-weight: 700; }
        .header-container .header-content .register-container .arrow-down {
          font-size: 48px;
          width: 72px;
          height: 72px; }

.section .section-title {
  text-align: center;
  margin: 0;
  font-size: 2.25rem;
  line-height: 2.25rem;
  color: #212121; }

.footer {
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 32px;
  color: #212121; }
  .footer .organizer-container {
    display: grid;
    justify-content: center; }
    .footer .organizer-container .organizer-title {
      margin: 16px;
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: center; }
    .footer .organizer-container .organizer-logo {
      width: 100%;
      max-width: 360px; }
  .footer .partners-container {
    display: grid;
    justify-content: center;
    margin-top: 32px; }
    .footer .partners-container .partner-title {
      margin: 16px;
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: center; }
    .footer .partners-container .logos-container {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .footer .partners-container .logos-container .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 16px; }
        .footer .partners-container .logos-container .logo-container .logo {
          width: 100%;
          max-width: 360px;
          max-height: 120px; }
  .footer .contact-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    padding-top: 32px; }
    .footer .contact-container .buttons-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      justify-items: center; }
      .footer .contact-container .buttons-container .button {
        max-width: 360px; }
    .footer .contact-container .contact, .footer .contact-container .media-contact {
      margin: 0;
      text-align: center; }
      .footer .contact-container .contact .link, .footer .contact-container .media-contact .link {
        color: #212121;
        text-decoration: underline; }

@media screen and (min-width: 425px) {
  .header .header-container {
    min-height: calc(100vh - 72px);
    padding: 36px; }
    .header .header-container .header-title {
      grid-template-columns: 180px 1fr; }
    .header .header-container .header-content .register-container .info-text {
      font-size: 18px; }
  .footer .organizer-title {
    font-size: 1.75rem; } }

@media screen and (min-width: 768px) {
  .header .header-container {
    min-height: calc(100vh - 100px);
    padding-bottom: 64px; }
    .header .header-container .header-title {
      grid-template-columns: 250px 1fr; }
      .header .header-container .header-title .header-title-headings .date {
        font-size: 1.5rem; }
      .header .header-container .header-title .header-title-headings .place {
        font-size: 1rem;
        margin-top: 8px; }
  .footer .partners-container .logos-container {
    flex-direction: row;
    flex-wrap: wrap; }
  .footer .organizer-title {
    font-size: 2rem;
    line-height: 2rem; }
  .footer .contact-container .buttons-container {
    justify-self: center;
    grid-template-columns: 320px 320px; }
    .footer .contact-container .buttons-container .become-a-partner-button {
      justify-self: flex-end; }
    .footer .contact-container .buttons-container .code-of-conduct-button {
      justify-self: flex-start; } }

@media screen and (min-width: 1024px) {
  .header .header-container .header-title {
    grid-template-columns: 300px 1fr; }
    .header .header-container .header-title .header-title-headings .date {
      font-size: 2rem; }
    .header .header-container .header-title .header-title-headings .place {
      font-size: 1.5rem;
      margin-top: 12px; }
  .footer .partners-container {
    max-width: 912px; }
    .footer .partners-container .logos-container .logo-container {
      margin: 32px; } }
